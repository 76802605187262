@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Montserrat:wght@800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: #f8f9fa;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
  }
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa
}

.hero-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  color: #ffd700;
  text-shadow: 4px 4px 0 #000;
  letter-spacing: -0.02em;
  line-height: 1.1;
}

.nav-logo {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-style: italic;
  background: linear-gradient(135deg, #1e3a8a 0%, #2563eb 100%);
  padding: 0.5rem 1rem;
  border: 2px solid white;
  border-radius: 0.5rem;
  color: white;
  text-shadow: 1px 1px 0 rgba(0,0,0,0.2);
}

.nav-items {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}