.App {
  min-height: 100vh;
}

button {
  cursor: pointer;
  transition: all 0.2s ease;
}

button:hover {
  transform: translateY(-1px);
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  border-color: #2563eb;
  ring: 2px solid #93c5fd;
}

.error-message {
  color: #dc2626;
  margin: 5px 0;
}